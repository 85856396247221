.home-container {
  width: 100%;
  margin: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #141414; /* Netflix-like dark background */
}

.featured-article-heading {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2.5rem;
  color: #e50914; /* Netflix red for headings */
  font-weight: bold;
}

.article-previews {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.article-preview {
  width: 300px;
  padding: 15px;
  border-radius: 8px;
  background-color: #141414;
  color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.article-preview:hover {
  transform: scale(1.03);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.article-preview h3 {
  font-size: 1.4em;
  margin-bottom: 12px;
}

.article-preview p {
  font-size: 1em;
  line-height: 1.5;
  margin-bottom: 15px;
}

.article-preview a {
  display: inline-block;
  background-color: #e50914;
  color: #ffffff;
  padding: 8px 12px;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.article-preview a:hover {
  background-color: #b20710;
}

.service-cards {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  flex-wrap: wrap;
  gap: 20px;
}

.service-card {
  flex: 1 1 300px; /* Allows wrapping and flexible sizing */
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: #141414;
  color: #fff;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.service-card h3 {
  margin-bottom: 10px;
  font-size: 1.8em;
}

.service-card p {
  font-size: 1em;
  margin-bottom: 15px;
}

.signup-btn {
  background-color: #e50914;
  color: #ffffff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  margin-top: 10px;
  display: inline-block;
  transition: background-color 0.3s;
}

.signup-btn:hover {
  background-color: #b20710;
}

@media (max-width: 768px) {
  .home-container {
    padding: 10px;
  }

  .service-cards,
  .article-previews {
    flex-direction: column;
  }

  .service-card,
  .article-preview {
    width: 100%;
  }
}
