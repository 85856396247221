/* Signup.css */
.signup-container {
  max-width: 600px; /* Increased width */
  margin: 2rem auto;
  padding: 20px;
  text-align: center;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.signup-container h1 {
  color: #333;
  margin-bottom: 15px;
}

.error-message {
  color: #db0000; /* Red color for error messages */
  margin-bottom: 10px;
}

.signup-container form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.signup-container input[type="email"],
.signup-container input[type="password"] {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

/* Dropdown Style */
.signup-container select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 1rem;
  margin-bottom: 10px; /* Space between the dropdown and the next input */
}

/* Style for the dropdown options */
.signup-container select option {
  color: #333; /* Text color for options */
}

/* Enhance the hover and focus styles for inputs and dropdown */
.signup-container input[type="email"]:hover,
.signup-container input[type="password"]:hover,
.signup-container select:hover,
.signup-container input[type="email"]:focus,
.signup-container input[type="password"]:focus,
.signup-container select:focus {
  border-color: #db0000; /* Highlight color when hovered or focused */
  outline: none;
}

.signup-container button[type="submit"] {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background: linear-gradient(45deg, #db0000 30%, #831010 90%);
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 20px; /* Space between the signup and google sign-in button */
}

/* Google Sign-in Button Style */
.google-signin-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #db4437; /* Google's brand color */
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 4px;
  margin-top: 20px; /* Space above the google sign-in button */
  margin-bottom: 20px; /* Space below the google sign-in button */
  text-transform: uppercase;
  cursor: pointer;
}

.google-signin-button:hover {
  background-color: #c1351d; /* Darker shade on hover */
}

.google-signin-button svg {
  margin-right: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .signup-container {
    width: 90%; /* Full width on smaller screens */
    padding: 15px;
  }

  .signup-container h1 {
    font-size: 1.5rem;
  }

  .signup-container input[type="email"],
  .signup-container input[type="password"],
  .signup-container select, /* Adjust font size of select on smaller screens */
  .signup-container button,
  .google-signin-button {
    font-size: 0.9rem;
  }
}
