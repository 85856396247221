
.headline {
  font-size: 3rem; /* Large font size for impact */
  font-weight: bold; /* Bold text for emphasis */
  text-transform: uppercase; /* Capitalize all letters for prominence */
  color: #db0000; /* Netflix's signature red color */
  text-align: center; /* Center alignment for the headline */
  margin: 2rem 0; /* Spacing above and below the headline */
  letter-spacing: 2px; /* Slight spacing between letters for readability */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
}

/* Grid Layout */
.articles-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Four columns */
  gap: 20px;
  padding: 20px;
}

/* Individual Article Card Styling */
.article-card {
  border: 1px solid #831010; /* Dark red border for emphasis */
  border-radius: 8px;
  padding: 15px;
  background-color: #141414; /* Dark background for contrast */
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  overflow: hidden; /* Ensures content fits within the card */
}

/* Article Title Styling */
.article-card h2, h3 {
  
  color: #fffafa; /* Black for title */
  font-size: 1.4em;
  font-weight: bold;
  text-align: left;
  margin-bottom: 10px;
}
/* Article Content Styling */
.article-card p{

  font-size: 1em;
  line-height: 1.6;
  color: #e5e5e5; /* Soft white for the article body */
  margin-bottom: 1rem;
}


/* Article Content Paragraph Styling */
.article-card p, ul, li, ol {
  font-size: 1em;
  line-height: 1.5;
  color: #ffffff; /* Darker gray for readability */
  margin-bottom: 15px;
}

/* Image and Video Styling */
.article-image, .article-iframe, .article-video {
  width: 100%; /* Full width of the card */
  height: auto; /* Maintain aspect ratio */
  border-radius: 4px; /* Slightly rounded corners for the media */
  margin-bottom: 1rem;
}

/* Featured Article Styling */
.featured-article {
  grid-column: span 2; /* Spans two columns */
  grid-row: span 2; /* Spans two rows */
}

/* ... existing styles ... */

.article-card button {
  padding: 8px 16px;
  background-color: #db0000;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.article-card button:hover {
  background-color: #831010;
}




/* Responsive Design for Medium Screens */
@media (max-width: 1200px) {
  .articles-grid {
    grid-template-columns: repeat(2, 1fr); /* Two columns */
  }
}

/* Responsive Design for Small Screens */
@media (max-width: 768px) {
  .articles-grid {
    grid-template-columns: 1fr; /* One column */
  }

  .featured-article {
    grid-column: span 1;
    grid-row: span 1;
  }
}
