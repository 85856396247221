
  /* AdminDashboard.css */


  .admin-dashboard {
    margin: 2rem auto; /* Centers the dashboard */
    padding: 2rem; /* Adds padding inside the dashboard */
    max-width: 300px; /* Limits the maximum width */
    color: #e5e5e5; /* Netflix-like text color */
    border-radius: 8px; /* Rounds the corners */
    background-color: #141414; /* Netflix-like background color */
   
  }
  
  .admin-dashboard h1 {
    border-bottom: 1px solid #e50914; /* Netflix-like accent color */
    padding-bottom: 1rem;
    margin-bottom: 2rem;
  }

  .listings {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem; /* Pushes the listings down a bit */
  }
  
  .listing {
    background-color: #181818; /* Slightly lighter background for each listing */
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds subtle shadow for depth */
    transition: transform 0.2s ease-in-out;
  }
  
  
  .listing:hover {
    transform: translateY(-5px); /* Slight lift effect on hover */
  }
  
  .listing h3 {
    color: #e50914; /* Accent color for titles */
  }
  .listing p {
    font-size: 1rem;
    margin: 0.5rem 0;
  }
  
  .listing button {
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  /* Styling for approve button */
  .listing button:first-of-type {
    background-color: #46d369; /* Netflix-like approved color */
    color: #141414;
  }
  
  .listing button:first-of-type:hover {
    background-color: #37a859; /* Darker green on hover */
  }
  
  /* Styling for reject button */
  .listing button:last-of-type {
    background-color: #e50914; /* Netflix-like accent color */
    color: #e5e5e5;
  }
  
  .listing button:last-of-type:hover {
    background-color: #b20710; /* Darker red on hover */
  }
  
  /* Additional responsive adjustments or animations can be added as needed */