.video-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Set the height to full viewport height */
  overflow: hidden;
}

.video-container .video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; /* Ensure video stays behind the overlay */
}

.video-container .overlay-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height to cover the video */
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent overlay */
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  z-index: 1; /* Above the video */
}

.video-container .overlay-text h1 {
  font-size: 4rem; /* Large font for impact */
  font-weight: bold;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6); /* Text shadow for depth */
}

.video-container .overlay-text p {
  font-size: 1.8rem; /* Smaller font for the paragraph */
}

/* Responsive Design Adjustments */
@media (max-width: 768px) {
  .video-container .overlay-text h1 {
    font-size: 3rem; /* Smaller font size for smaller screens */
  }

  .video-container .overlay-text p {
    font-size: 1.4rem;
  }
}

@media (max-width: 576px) {
  .video-container .overlay-text h1 {
    font-size: 2.5rem;
  }

  .video-container .overlay-text p {
    font-size: 1.2rem;
  }
}
