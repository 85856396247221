/* NavBar.css */
.navbar-container {
  background-color: #141414; /* Dark background */
  color: #ffffff;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.navbar-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-title {
  font-size: 1.8rem;
  text-decoration: none;
  color: #e50914; /* Reciprofy's red color */
}

.navbar-links {
  display: flex;
  align-items: center;
}

.navbar-link {
  margin: 0 15px;
  text-decoration: none;
  color: #ffffff;
  transition: color 0.2s ease-in-out;
}

.navbar-link:hover {
  color: #e50914; /* Hover effect */
}

.profile-dropdown-toggle {
  position: relative;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.profile-dropdown-toggle:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-dropdown {
  display: none;
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #141414;
  color: #ffffff;
  min-width: 160px;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateY(-10px);
}

/* When rendered, the navbar-dropdown will be visible */
.navbar-dropdown.show {
  display: block;
  opacity: 1;
  transform: translateY(0);
}

.dropdown-link {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: background-color 0.2s;
}

.dropdown-link:hover {
  background-color: #e50914;
}

@media (max-width: 768px) {
  .navbar-toolbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-title {
    margin-bottom: 10px;
  }

  .navbar-links {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .navbar-link {
    width: 100%;
    text-align: left;
    padding: 10px 0;
  }

  .navbar-dropdown {
    right: auto;
    left: 0;
    width: 100%;
  }

  .profile-dropdown-toggle {
    width: 100%;
    justify-content: space-between;
  }
}
