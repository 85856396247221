.user-profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  max-width: 600px;
  margin: 2rem auto;
  background-color: #141414; /* Netflix-like dark background */
  color: #e5e5e5; /* Light text for contrast */
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
}

.user-profile-container form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.user-profile-container form label {
  color: #e5e5e5;
  margin-bottom: .5rem;
  font-size: 1rem;
}

.user-profile-container form input[type="text"],
.user-profile-container form textarea {
  padding: .8rem;
  background-color: #333;
  border: 1px solid #777;
  border-radius: 4px;
  color: #fff;
}

.user-profile-container form textarea {
  height: 120px;
  resize: vertical;
}

.user-profile-container form button {
  padding: 10px 20px;
  margin-top: .5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.user-profile-container form .upload-image-btn,
.user-profile-container form .save-changes-btn,
.user-profile-container form .cancel-btn {
  align-self: center;
}

/* Style for upload image button */
.upload-image-btn {
  background-color: #333;
  color: #fff;
}

.upload-image-btn:hover {
  background-color: #555;
}

/* Style for save changes button */
.save-changes-btn {
  background-color: #E50914; /* Netflix red */
  color: white;
}

.save-changes-btn:hover {
  background-color: #B20710; /* Darker shade for hover */
}

/* Style for cancel button */
.cancel-btn {
  background-color: transparent;
  color: #E50914; /* Red text */
  border: 2px solid #E50914; /* Red border */
}

.cancel-btn:hover {
  background-color: #E50914;
  color: white;
}

/* Profile Image styling */
.profile-avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #E50914; /* Red border for emphasis */
}

@media (max-width: 768px) {
  .user-profile-container {
    padding: 1rem;
    max-width: 90%;
  }

  .user-profile-container form button {
    padding: 8px 16px;
  }

  .profile-avatar {
    width: 100px;
    height: 100px;
  }
}
