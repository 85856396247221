/* SignIn.css */
.signin-container {
    max-width: 400px;
    margin: 2rem auto;
    padding: 20px;
    text-align: center;
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .signin-container h1 {
    color: #333;
    margin-bottom: 15px;
  }
  
  .error-message {
    color: #db0000;
    margin-bottom: 10px;
  }
  
  .signin-container form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .signin-container input[type="email"],
  .signin-container input[type="password"] {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .signin-container button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background: linear-gradient(45deg, #db0000 30%, #831010 90%);
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .signin-container button:hover {
    background: linear-gradient(45deg, #831010 30%, #db0000 90%);
  }
  
  /* Google Sign-in Button Style */
  .google-signin-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #db4437;
    color: white;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 4px;
    margin-top: 20px;
    text-transform: uppercase;
    cursor: pointer;
  }
  
  .google-signin-button:hover {
    background-color: #c1351d;
  }
  
  .google-signin-button svg {
    margin-right: 10px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .signin-container {
      width: 90%;
      padding: 15px;
    }
  
    .signin-container h1 {
      font-size: 1.5rem;
    }
  
    .signin-container input[type="email"],
    .signin-container input[type="password"],
    .signin-container button,
    .google-signin-button {
      font-size: 0.9rem;
    }
  }
  