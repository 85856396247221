.vendor-marketplace-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vendor-search {
  width: 100%;
  max-width: 600px;
  padding: 10px 15px;
  margin-bottom: 30px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.vendor-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 20px;
  width: 100%;
  max-width: 800px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.vendor-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.vendor-card h3 {
  background-color: #FF0000; /* For a Netflix-inspired theme */
  color: white;
  padding: 15px;
  margin: 0;
  font-size: 1.5rem;
}

.vendor-card p {
  padding: 0 15px;
  margin: 10px 0;
  font-size: 1rem;
  color: #333;
}

.vendor-media {
  display: flex;
  overflow-x: auto;
  padding: 15px;
}

.vendor-media img {
  height: 150px;
  margin-right: 10px;
  border-radius: 8px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .vendor-card {
    max-width: 100%;
  }
}
