/* Newsletter Signup Styles */
.newsletter-signup {
    max-width: 600px; /* Increased width */
    margin: 20px auto;
    padding: 30px;
    text-align: center;
    background-color: #f9f9f9; /* Light background */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .newsletter-signup h2 {
    color: #db0000; /* Primary red for the title */
    margin-bottom: 20px;
    font-size: 1.8rem;
  }
  
  .newsletter-signup p {
    color: #564d4d; /* Grey for text */
    margin-bottom: 25px;
    font-size: 1.1rem;
  }
  
  .newsletter-signup form {
    display: flex;
    gap: 15px;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .newsletter-signup input[type="email"] {
    flex-grow: 1;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .newsletter-signup button {
    padding: 12px 25px;
    border: none;
    border-radius: 5px;
    background-color: #831010; /* Secondary dark red for button */
    color: white;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .newsletter-signup button:hover {
    background-color: #db0000; /* Lighten button on hover */
  }
  
  .newsletter-signup button:disabled {
    background-color: #a12f2f; /* Disabled state */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .newsletter-signup {
      padding: 20px;
      max-width: 90%; /* Responsive width adjustment */
    }
  
    .newsletter-signup form {
      flex-direction: column;
    }
  
    .newsletter-signup input[type="email"], .newsletter-signup button {
      width: 100%;
      margin: 5px 0;
    }
  
    .newsletter-signup h2 {
      font-size: 1.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .newsletter-signup h2, .newsletter-signup p {
      font-size: 1.2rem;
    }
  
    .newsletter-signup input[type="email"], .newsletter-signup button {
      font-size: 1rem;
    }
  }
  