.vendor-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    margin-bottom: 20px;
  }
  
  .vendor-card-header img {
    width: 100%;
    height: auto;
    display: block; /* Removes bottom space under the image */
  }
  
  .vendor-card-body {
    padding: 15px;
  }
  
  .vendor-card-body h3 {
    margin-top: 0;
  }
  
  .vendor-card-footer {
    display: flex;
    overflow-x: auto;
    padding: 10px;
  }
  
  .vendor-card-footer img {
    height: 100px;
    margin-right: 10px;
    border-radius: 4px;
  }
  