.vendor-profile {
    max-width: 900px;
    margin: 40px auto;
    padding: 20px;
    background: #141414; /* Netflix background color */
    border-radius: 8px;
    color: #fff; /* Text color for readability */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8); /* Strong shadow for a pop-out effect */
  }
  
  .vendor-profile-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px; /* Space below image */
  }
  
  .loading, .error, .vendor-not-found {
    text-align: center;
    font-size: 1.5rem;
    color: #e50914; /* Netflix red for important messages */
  }
  
  .vendor-details {
    margin-top: 20px;
    border-top: 1px solid #e50914; /* Horizontal rule in Netflix red */
    padding-top: 20px;
  }
  
  .vendor-profile h1 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 0.5em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Text shadow for depth */
  }
  
  .vendor-profile p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 10px;
  }
  
  .vendor-details strong {
    color: #e50914; /* Netflix red for highlighting key terms */
  }
  
  /* Add additional media queries for responsiveness */
  @media (max-width: 768px) {
    .vendor-profile {
      padding: 15px;
    }
  
    .vendor-profile h1 {
      font-size: 2rem;
    }
  }
  