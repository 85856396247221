/* App.css */

/* Ensure html and body are taking the full height of the viewport */
html, body {
    height: 100%;
    margin: 0;
  }
  
  /* Use a flex layout to push the footer to the bottom */
  .content-wrap {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  /* Style for the page content, this will allow you to control the layout of pages */
  .page-content {
    flex: 1; /* This will push the footer to the bottom */
    /* Add any additional styles you want for the main content area */
  }
  