/* Define your color palette as CSS variables for easy reference */
:root {
    --primary-color: #db0000;
    --secondary-color: #831010;
    --text-color: #ffffff;
    --background-color: #333;
    --link-color: #ffffff;
    --link-hover-color: #db0000;
    --footer-padding: 20px;
    --footer-height: auto; /* Adjust as needed */
  }
  
  .footer-container {
    background-color: var(--background-color);
    color: var(--text-color);
    padding: var(--footer-padding);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    height: var(--footer-height);
    background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  }
  
  
  .footer-links,
  .footer-socials {
    display: flex;
    gap: 30px; /* Increase spacing between items */
  }
  
  .footer-links a,
  .footer-socials a {
    color: var(--link-color);
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-links a:hover,
  .footer-socials a:hover {
    color: var(--link-hover-color); /* Change color on hover */
  }
  
  .footer-socials {
    align-items: center;
  }
  
  .footer-socials svg {
    font-size: 2rem; /* Icon size */
    margin-right: 8px; /* Space after the icon before text */
  }
  .footer-container {
    flex-shrink: 0; /* Prevent the footer from shrinking */
    /* Your existing footer styles */
  }
  
  /* Responsive adjustments */
  @media (max-width: 600px) {
    .footer-container {
      flex-direction: column;
      text-align: center;
      gap: 15px; /* Space between stacked items */
    }
  
    .footer-links,
    .footer-socials {
      flex-direction: column;
      align-items: center;
    }
  }

  /* For mobile screens, newsletter signup takes full width */
@media (max-width: 768px) {
    .newsletter-signup {
      flex-basis: 100%;
      margin-right: 0;
      margin-bottom: 20px; /* Adjust as needed for spacing */
    }
  
    .footer-links-socials-container {
      flex-basis: 100%;
    }
  }
  
  /* For larger screens, adjust as needed */
  @media (min-width: 769px) {
    .newsletter-signup {
      flex-basis: calc(40% - 20px); /* Adjust width to be less than half the footer width minus any margin */
      margin-right: 20px; /* Space between newsletter and the rest */
    }
  
    .footer-links-socials-container {
      flex-basis: calc(60% - 20px); /* Adjust width to take remaining space */
    }
  }
  
  /* Additional styling for newsletter if part of footer */
  .newsletter-signup {
    flex-basis: 100%; /* Take full width on mobile */
    max-width: 400px; /* Adjust width for larger screens */
    margin-bottom: var(--footer-padding);
    
  }
  
  /* Add any additional specific styling for the newsletter component */
  